import { Box, styled, Typography } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'

import { COLORS } from '../../../constants/colors'
import { useAppDispatch } from '../../../stores/store'
import { errorMessageSelector } from '../../../stores/visible/selector'
import { setGlobalErrorMessage } from '../../../stores/visible/slice'
import CancelIcon from '../../global/icons/CancelIcon'
import CustomButton from '../../global/interactions/CustomButton'
import CustomDialog from '../../global/interactions/CustomDialog'

const ErrorDialog = () => {
  const dispatch = useAppDispatch()
  const errorMessage = useSelector(errorMessageSelector)
  return (
    <CustomDialog
      minHeight='20px'
      isOpen={Boolean(errorMessage)}
      size='small'
      content={
        <Card>
          <ErrorIcon>
            <CancelIcon size={36} color={COLORS.red400} />
          </ErrorIcon>
          <Typography variant='h5' marginTop={2} marginBottom={1}>
            Error
          </Typography>
          <Typography>{errorMessage}</Typography>
          <Box marginTop={4} alignSelf='stretch'>
            <CustomButton fullWidth color='error' text='Ok' onClick={() => dispatch(setGlobalErrorMessage(''))} />
          </Box>
        </Card>
      }
    />
  )
}

const Card = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  padding: theme.spacing(4)
}))

const ErrorIcon = styled(Box)({
  width: 64,
  height: 64,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: COLORS.red200,
  borderRadius: '50%'
})

export default ErrorDialog
