import React, { useEffect, useState } from 'react'
import { Toaster } from 'react-hot-toast'
import { useSelector } from 'react-redux'
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router'

import ErrorDialog from './components/project/ui/ErrorDialog'
import { RENDER_PROTECTED_ROUTES, RENDER_UNPROTECTED_ROUTES } from './configs/routes'
import { insurerPath, loginPath } from './constants/route-name'
import { AuthService } from './features/auth/services/AuthService'
import { resetCustomerCompanyInfoState } from './features/customer/stores/company-info/slice'
import { resetEmployeeState } from './features/customer/stores/employee-info/slice'
import { resetCustomerInsuranceInfoState } from './features/customer/stores/insurance-info/slice'
import { resetPurchaseOrderState } from './features/customer/stores/order/slice'
import { resetWelfareState } from './features/customer/stores/welfare/slice'
import { resetBenefitInfoState } from './features/e-claim/stores/benefit-info/slice'
import { resetEClaimInfoState } from './features/e-claim/stores/e-claim-info/slice'
import { resetFlexBenefitState } from './features/flex-benefit/stores/slice'
import { resetInsurerState } from './features/insurance/stores/insurer/slice'
import { closePreviewPlan, resetPlanState } from './features/insurance/stores/plan/slice'
import { resetMedicalState } from './features/medical/stores/slice'
import { resetRoleState } from './features/user/stores/role/slice'
import { resetUserState } from './features/user/stores/user/slice'
import AppLayout from './layout/AppLayout'
import AuthLayout from './layout/AuthLayout'
import OnboardingPage from './pages/OnboardingPage'
import ShowcasePage from './pages/ShowcasePage'
import { currentUserSelector } from './stores/current-user/selector'
import { resetLanguage } from './stores/language/slice'
import { useAppDispatch } from './stores/store'
import { fontSize } from './themes/themes'

const toastOptions = {
  style: {
    fontSize: fontSize.h6,
    fontWeight: 500,
    borderRadius: '50px'
  }
}

function App() {
  const [isLoading, setIsLoading] = useState(true)

  const dispatch = useAppDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const currentUser = useSelector(currentUserSelector)

  const token = localStorage.getItem('token')
  const notFoundElement = currentUser?.name ? <Navigate to={insurerPath} /> : <Navigate to={loginPath} />

  const onFetchUser = async () => {
    if (token) {
      try {
        setIsLoading(true)
        await dispatch(AuthService.getCurrentUserAsyncThunk()).unwrap()
      } catch (error) {
        localStorage.clear()
      }
    }
    setIsLoading(false)
  }

  useEffect(() => {
    onFetchUser()
    if (location.pathname === '/') navigate(loginPath, { replace: true })
  }, [])

  useEffect(() => {
    if (token) dispatch(AuthService.getCurrentUserAsyncThunk())
    dispatch(resetInsurerState())
    dispatch(resetPlanState())
    dispatch(resetUserState())
    dispatch(resetRoleState())
    dispatch(resetCustomerCompanyInfoState())
    dispatch(resetCustomerInsuranceInfoState())
    dispatch(resetEmployeeState())
    dispatch(resetPurchaseOrderState())
    dispatch(resetEClaimInfoState())
    dispatch(resetBenefitInfoState())
    dispatch(resetFlexBenefitState())
    dispatch(resetWelfareState())
    dispatch(resetLanguage())
    dispatch(resetMedicalState())
    dispatch(closePreviewPlan())
  }, [location.pathname])

  if (isLoading)
    return (
      <Routes>
        <Route path='*' element={<OnboardingPage />} />
      </Routes>
    )

  return (
    <>
      <ErrorDialog />
      <Toaster toastOptions={toastOptions} />
      <Routes>
        <Route path='/' element={notFoundElement} />
        {currentUser?.name ? (
          <Route path='/' element={<AppLayout />}>
            <Route index element={<></>} />
            {RENDER_PROTECTED_ROUTES.map(
              (route) =>
                (currentUser.permissions.includes(route.permissions) || !route.permissions) && (
                  <Route key={route.path} path={route.path} element={route.component} />
                )
            )}
          </Route>
        ) : (
          <Route path='/' element={<AuthLayout />}>
            {RENDER_UNPROTECTED_ROUTES.map((route) => (
              <Route key={route.path} path={route.path} element={route.component} />
            ))}
          </Route>
        )}
        <Route path='*' element={notFoundElement} />
        <Route path='/showcase' element={<ShowcasePage />} />
      </Routes>
    </>
  )
}

export default App
