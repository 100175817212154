import axios from 'axios'
import { toast } from 'react-hot-toast'

import { baseApiUrl } from '../configs/api'
import { COLORS_THEME } from '../constants/colors'
import { store } from '../stores/store'
import { setGlobalErrorMessage } from '../stores/visible/slice'

export const AxiosService = axios.create({
  baseURL: baseApiUrl
})

AxiosService.interceptors.request.use(
  async function (config) {
    const token = localStorage.getItem('token')
    const language = localStorage.getItem('language')
    if (token && config.headers) {
      config.headers.authorization = `Bearer ${token}`
    }
    if (language && config.headers) {
      config.headers['Accept-Language'] = language
    }
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

AxiosService.interceptors.response.use(
  function (response) {
    if (response.config.method !== 'get' && !response.data.imageUrl && !response.data.fileUrl) {
      toast.success('Your request was successful!', {
        style: {
          color: 'white',
          backgroundColor: COLORS_THEME.primary
        }
      })
    }
    return response
  },
  function (error) {
    let errorMessage = ''
    errorMessage = error.response.data.message || 'An Unexpected Error Occurred!'
    if (error.message === 'Request failed with status code 404' && !isNaN(error.response.data?.size)) {
      errorMessage = 'No data found!'
    }
    store.dispatch(setGlobalErrorMessage(errorMessage))
    return Promise.reject(error)
  }
)
